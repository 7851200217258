
import { smoothScrollToTargetId } from '~/helpers/util'
import { CurrentView } from '~/store/building/-constants'
import universeConstants from '~/store/universe/-constants'
import projectConstants from '~/store/project/-constants'
import baseConstants from '~/store/base/-constants'
import tutorialConstants from '@/store/tutorial/-constants'

export default {
  name: 'SectionBuildingV1',
  props: {
    data: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {
          backgroundLoader: '',
          contentLoader: []
        }
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    const regex = /\/project\/.+\/space\/[0-9]+/i
    const regexEmbed = /\/embed\/.+\/space\/[0-9]+/i
    const isSpaceRoute = regex.test(this.$route.path)
    const isEmbedSpaceRoute = regexEmbed.test(this.$route.path)
    return {
      isFloorView: false,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      headerHeight: 0,
      embedCartIsOpen: false,
      drawerAvailabilityOpen: !isSpaceRoute && !isEmbedSpaceRoute,
      drawerShortlistOpen: false,
      spaceDetailsOpen: isSpaceRoute || isEmbedSpaceRoute,
      loadingErrorOccurred: false
    }
  },
  computed: {
    generalConfig() {
      return this.$store?.state?.base?.meta?.generalConfig
    },
    activeProject() {
      return this.$store?.state?.project?.activeProject
    },
    disableButtonsActions() {
      const { disableButtonsActions } = this.generalConfig
      const findDisableButtonsActionsByProject = disableButtonsActions?.[this.activeProject]
        ? disableButtonsActions[this.activeProject]
        : null
      if (findDisableButtonsActionsByProject) {
        return {
          exploreSpacePage: findDisableButtonsActionsByProject.exploreSpacePage || false,
          requestOffer: findDisableButtonsActionsByProject.requestOffer || false,
          disableWalkThrough: findDisableButtonsActionsByProject.disableWalkThrough || false
        }
      }
      return {
        exploreSpacePage: false,
        requestOffer: false,
        disableWalkThrough: false
      }
    },
    buildingStore() {
      return this.$store.state.building
    },
    shortlistState() {
      return this.$store.getters?.getShortlist || false
    },
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen
    },
    contentLoaderData() {
      return this.data.contentLoader
    },
    backgroundLoaderUrl() {
      return `${this.$store.getters['base/cdnBase']}/${this.data.backgroundLoader}`
    },
    isSpaceRoute() {
      const regex = /\/project\/.+\/space\/[0-9]+/i
      return regex.test(this.$route.path)
      // return this.$route.name === 'SpecificSpace'
    },
    isEmbedSpaceRoute() {
      const regex = /\/embed\/.+\/space\/[0-9]+/i
      return regex.test(this.$route.path)
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    unitOfMeasure() {
      return this.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    selectedSpace() {
      return Number(this.$route.params.space)
    },
    openShortlist() {
      return this.$store.state?.universe?.shortlistInfo?.open || false
    },
    apartmentViewMode() {
      return this.$store.state?.universe?.apartmentView || false
    },
    loadingScreenActive() {
      return this.$store.state.building.loadingScreenActive
    },
    viewMode() {
      return this.buildingStore.floorViewMode
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    shouldOnlyDisplay3dSection() {
      return this.guidedTourModeEnabled
    },
    availableTours() {
      return this.$store.state.guidedTour.availableTours || []
    },
    hasGuidedTours() {
      return this.availableTours.length > 0
    },
    enableZoomSlider() {
      return this.buildingStore.settings?.floor?.enableZoomSlider || false
    }
  },
  watch: {
    selectedSpace(val) {
      if (!val) {
        return
      }
      if (window.innerWidth < 768) {
        this.sidebarHideMobile()
      } else if (!this.spaceDetailsOpen) {
        this.handleExpandSpaceDetails()
      }
    }
  },
  beforeDestroy() {
    this.sidebarHideMobile()
    window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('keydown', this.closeInfoModal)
  },
  mounted() {
    this.sidebarHideMobile()
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === 'building/setLoadingScreenStatus' ||
        mutation.type === 'building/setCurrentView'
      ) {
        this.isFloorView = state.building.currentView === CurrentView.FLOOR
      }
      if (mutation.type === 'building/setAssetLoadingError') {
        this.loadingErrorOccurred = mutation.payload
      }
    })
    window.addEventListener('resize', this.resizeListener)
    window.addEventListener('keydown', this.closeInfoModal)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
  },
  beforeMount() {
    this.$store.dispatch(
      universeConstants.withNamespace(universeConstants.action.TOGGLE_APARTMENT_VIEW),
      false
    )
    this.$store.dispatch(
      projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
      null
    )
  },
  methods: {
    handleInfoTrigger() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open
        }
      )
    },
    closeInfoModal() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: false
        }
      )
    },
    infoToggle() {},
    goToNextSection() {
      const sections = this.$parent.$parent.sections
      const targetSection = sections[this.orderNo]
      const targetString = targetSection.hashAnchor
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    sidebarHideMobile() {
      if (window.innerWidth < 768) {
        this.handleCloseAvailability()
        this.handleCloseShortlist()
        this.handleCloseSpaceDetails()
      }
    },
    resizeListener(e) {
      this.sidebarHideMobile()
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
    },
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    },
    toggleEmbedSidebar() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
        false
      )
      this.embedCartIsOpen = false
    },
    toggleCartSidebar() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
        false
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      )
      this.embedCartIsOpen = !this.embedCartIsOpen
    },
    handleExpandAvailability() {
      this.$refs['availability-drawer']?.toggleDrawer()
      this.drawerAvailabilityOpen = !this.drawerAvailabilityOpen
      if (this.drawerAvailabilityOpen) {
        this.handleCloseShortlist()
        this.handleCloseSpaceDetails()
      }
      if (window.innerWidth < 768 && !this.$store.state.tutorial.AVAILABILITY_FILTERS_MOBILE) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'AVAILABILITY_FILTERS_MOBILE'
        )
      }

      const manager = this.engine3d.getClientManager()
      const intVal = setInterval(() => {
        manager?.resize(false, false)
      }, 10)
      setTimeout(() => {
        manager?.resize()
        clearInterval(intVal)
      }, 501)
    },
    handleExpandShortlist() {
      this.$refs['shortlist-drawer']?.toggleDrawer()
      this.drawerShortlistOpen = !this.drawerShortlistOpen
      if (this.drawerShortlistOpen) {
        this.handleCloseAvailability()
        this.handleCloseSpaceDetails()
      }
    },
    handleExpandSpaceDetails() {
      this.$refs['spacedetails-drawer']?.toggleDrawer()
      this.spaceDetailsOpen = !this.spaceDetailsOpen
      if (this.spaceDetailsOpen) {
        this.handleCloseAvailability()
        this.handleCloseShortlist()
      } else if (window.innerWidth < 768 && !this.$store.state.tutorial.APARTMENT_INFO_MOBILE) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'APARTMENT_INFO_MOBILE'
        )
      }

      const manager = this.engine3d.getClientManager()
      const intVal = setInterval(() => {
        manager?.resize(false, false)
      }, 10)
      setTimeout(() => {
        manager?.resize()
        clearInterval(intVal)
      }, 501)
    },
    handleCloseAvailability() {
      this.$refs['availability-drawer']?.closeDrawer()
      this.drawerAvailabilityOpen = false
    },
    handleCloseShortlist() {
      this.$refs['shortlist-drawer']?.closeDrawer()
      this.drawerShortlistOpen = false
    },
    handleCloseSpaceDetails() {
      this.$refs['spacedetails-drawer']?.closeDrawer()
      this.spaceDetailsOpen = false
    },
    handleCloseGuidedTourPresentation() {
      this.$refs['guided-tour-presentation-drawer']?.closeDrawer()
    }
  }
}
