import { render, staticRenderFns } from "./SectionBuildingV2.vue?vue&type=template&id=15195edd&"
import script from "./SectionBuildingV2.vue?vue&type=script&lang=js&"
export * from "./SectionBuildingV2.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuildingV2.vue?vue&type=style&index=0&id=15195edd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionBuildingV2.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Fproject%2Fbuilding%2FSectionBuildingV2.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectBuildingV2Organism3DContainer: require('/app/components/organisms/project/buildingV2/Organism3DContainer.vue').default,OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer: require('/app/components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue').default,OrganismsProjectBuildingV2OrganismProjectUI: require('/app/components/organisms/project/buildingV2/OrganismProjectUI.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,OrganismsProjectBuildingV2OrganismSpaceDetailsV2: require('/app/components/organisms/project/buildingV2/OrganismSpaceDetailsV2.vue').default,OrganismsProjectBuildingOrganismTutorial: require('/app/components/organisms/project/building/OrganismTutorial.vue').default,MoleculesProjectBuildingV2MoleculeBuildingSpaceSwitchFloorV2: require('/app/components/molecules/project/buildingV2/MoleculeBuildingSpaceSwitchFloorV2.vue').default,OrganismsProjectBuildingOrganismGuidedTourLauncher: require('/app/components/organisms/project/building/OrganismGuidedTourLauncher.vue').default,OrganismsProjectBuildingOrganismGuidedTourTimeline: require('/app/components/organisms/project/building/OrganismGuidedTourTimeline.vue').default,MoleculesProjectBuildingV2MoleculeFloorOrientationBarV2: require('/app/components/molecules/project/buildingV2/MoleculeFloorOrientationBarV2.vue').default,AtomsCommonAtomInfoPopup: require('/app/components/atoms/common/AtomInfoPopup.vue').default})
