
export default {
  name: 'SectionGalleryV2',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    content() {
      return this.data
    },
    images() {
      return this.data.images
    },
    videos() {
      return this.data.videos
    }
  },
  methods: {
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    }
  }
}
