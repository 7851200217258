import { render, staticRenderFns } from "./SectionSpecsV2.vue?vue&type=template&id=142ee755&"
import script from "./SectionSpecsV2.vue?vue&type=script&lang=js&"
export * from "./SectionSpecsV2.vue?vue&type=script&lang=js&"
import style0 from "./SectionSpecsV2.vue?vue&type=style&index=0&id=142ee755&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,AtomsCommonAtomToggle: require('/app/components/atoms/common/AtomToggle.vue').default,OrganismsProjectOrganismTabsTechnicalSpecs: require('/app/components/organisms/project/OrganismTabsTechnicalSpecs.vue').default})
