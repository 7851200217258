
import projectConstants from '~/store/project/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'TemplateProject',
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ]
    }
  },
  props: {
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sections: []
    }
  },
  head() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `${this.$store.state.base.meta.generalConfig.baseTitle} - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.base.meta.generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    buildingStore() {
      return this.$store.state.building
    },
    meta() {
      // return this.$store.state.project.meta;
      return {
        ...this.$store.state.base.meta.projectConfig.meta,
        baseTitle: this.$store.state.base.meta.generalConfig.baseTitle
      }
    },
    sectionsData() {
      return this.$store.state.base.meta.projectConfig.sections
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },
    buildingRef() {
      const found = this.$refs.sections.find(function (s) {
        return s.$refs.buildingSection
      })
      if (!found) return null
      if (found.$refs.buildingSection.variant === 'v2') {
        return found.$refs.buildingSection.$refs.buildingVariant.$refs.container3d.$refs.building
      }
      return found.$refs.buildingSection.$refs.buildingVariant.$refs.building
    },
    portfolioPageDisabled() {
      return this.$store.state.base.meta.generalConfig.portfolioPageDisabled
    }
  },
  watch: {
    activeProject: function (value, old) {
      if (value !== old) {
        this.portfolioPageDisabled && this.buildingRef.initializeBuilding3D()
      }
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections()
        if (this.$store.state.base.documentIsReady) {
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
            false
          )
        }
        if (to.hash) {
          document.querySelector(to.hash).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    }
  },
  created() {
    this.sortSections()
    this.$store.dispatch(
      projectConstants.withNamespace(projectConstants.action.LOAD_LANDLORD_DETAILS)
    )
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('keydown', this.disableArrowScroll, false)
    setTimeout(() => {}, 3000)
    this.$store.state.project.activeProject && this.buildingRef.initializeBuilding3D()
    this.$store.dispatch(
      requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_STATE),
      null
    )
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.disableArrowScroll)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    sortSections() {
      this.sections = [...this.sectionsData].sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1
        }
        if (a.order_no > b.order_no) {
          return 1
        }
        return 0
      })
    },
    disableArrowScroll(e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
        e.preventDefault()
      }
    },
    handleScroll(event, element) {
      const scrollY = window.scrollY + 100
      document.querySelectorAll('a.navigation-link[href*="#"]').forEach((link) => {
        link.classList.remove('active')
        // const section = document.getElementById(`${link.getAttribute('href').substring(1)}`).parentElement;
        const section = document.getElementById(`${link.href.split('#')[1]}`).parentElement
        if (section.offsetTop <= scrollY && section.offsetTop + section.offsetHeight > scrollY) {
          link.classList.add('active')
        }
      })
    },
    removePageLoader() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
        false
      )
    }
  }
}
