
export default {
  name: 'SectionSpecsV2',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    let activeTab = ''
    if (this.data.tabs && this.data.tabs.length) {
      activeTab = this.data.tabs[0].tabId
    }
    return {
      activeTab,
      toggled: false
    }
  },
  computed: {
    hasResourcesModal() {
      return false
    }
  },
  watch: {
    toggled: function (value) {
      this.activeTab = value ? 'exterior' : 'interior'
    }
  },
  beforeUpdate() {
    if (this.data.tabs && this.data.tabs.length && !this.activeTab) {
      this.activeTab = this.data.tabs[0].tabId
    }
  },
  methods: {
    handleTabChange(tabId) {
      this.activeTab = tabId
      this.toggled = tabId === 'exterior'
    },
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    }
  }
}
