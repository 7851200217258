
import OrganismLocation from '../../../../components/organisms/project/OrganismLocation'
import AtomTitle from '../../../../components/atoms/common/AtomTitle'

export default {
  name: 'SectionLocationV2',
  components: { AtomTitle, OrganismLocation },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  created() {}
}
