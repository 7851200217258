import { render, staticRenderFns } from "./SectionBuildingV1.vue?vue&type=template&id=0820ed15&"
import script from "./SectionBuildingV1.vue?vue&type=script&lang=js&"
export * from "./SectionBuildingV1.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuildingV1.vue?vue&type=style&index=0&id=0820ed15&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionBuilding.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Fproject%2Fbuilding%2FSectionBuildingV1.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomLoadingError: require('/app/components/atoms/common/AtomLoadingError.vue').default,OrganismsProjectOrganismLoader: require('/app/components/organisms/project/OrganismLoader.vue').default,OrganismsProjectBuildingOrganismBuilding: require('/app/components/organisms/project/building/OrganismBuilding.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,MoleculesMoleculeBuildingZoomSlider: require('/app/components/molecules/MoleculeBuildingZoomSlider.vue').default,OrganismsCommonAvailabilityOrganismSpaceDetails: require('/app/components/organisms/common/availability/OrganismSpaceDetails.vue').default,OrganismsCommonAvailabilityOrganismAvailabilityDrawer: require('/app/components/organisms/common/availability/OrganismAvailabilityDrawer.vue').default,OrganismsCommonAvailabilityOrganismShortlistDrawer: require('/app/components/organisms/common/availability/OrganismShortlistDrawer.vue').default,OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer: require('/app/components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue').default,OrganismsProjectBuildingOrganismTutorial: require('/app/components/organisms/project/building/OrganismTutorial.vue').default,MoleculesMoleculeBuildingSpaceSwitchFloor: require('/app/components/molecules/MoleculeBuildingSpaceSwitchFloor.vue').default,OrganismsProjectBuildingOrganismGuidedTourLauncher: require('/app/components/organisms/project/building/OrganismGuidedTourLauncher.vue').default,OrganismsProjectBuildingOrganismGuidedTourTimeline: require('/app/components/organisms/project/building/OrganismGuidedTourTimeline.vue').default,MoleculesMoleculeBuildingOrientationBar: require('/app/components/molecules/MoleculeBuildingOrientationBar.vue').default,AtomsCommonAtomInfoPopup: require('/app/components/atoms/common/AtomInfoPopup.vue').default})
