
import projectConstants from '~/store/project/-constants'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'TemplateProjectSpace',
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `%s - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ]
    }
  },
  props: {
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sections: []
    }
  },
  head() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `${this.$store.state.base.meta.generalConfig.baseTitle} - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.base.meta.generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    meta() {
      return {
        ...this.$store.state.base.meta.projectConfig.meta,
        baseTitle: this.$store.state.base.meta.generalConfig.baseTitle
      }
    },
    sectionsData() {
      return this.$store.state.base.meta.projectConfig.sections
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    buildingRef() {
      const found = this.$refs.sections?.find((s) => s.$refs.buildingSection)
      if (!found) return null
      if (found.$refs.buildingSection.variant === 'v2') {
        return found.$refs.buildingSection.$refs.buildingVariant.$refs.container3d.$refs.building
      }
      return found.$refs.buildingSection.$refs.buildingVariant.$refs.building
    },
    spaceId() {
      return Number(this.$route.params.space)
    },
    space() {
      return this.$store.state.project.spaceData
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    shouldOnlyDisplay3dSection() {
      return this.guidedTourModeEnabled
    }
  },
  watch: {
    async spaceId(val) {
      console.log('spaceId change to', val)
      await this.loadSpaceData()
      console.log('after space data load')
      this.initializeFloor3D()
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections()
        if (this.$store.state.base.documentIsReady) {
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
            false
          )
        }
        if (to.hash) {
          document.querySelector(to.hash).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    }
  },
  created() {
    this.sortSections()
    this.$store.dispatch(
      projectConstants.withNamespace(projectConstants.action.LOAD_LANDLORD_DETAILS)
    )
    this.loadSpaceData()
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.loadSpaceData()
  },
  mounted() {
    window.addEventListener('keydown', this.disableArrowScroll, false)
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
    this.initializeFloor3D()
  },
  beforeDestroy() {
    this.$store.commit(
      projectConstants.withNamespace(projectConstants.mutation.SET_SPACE_DATA),
      null
    )
    window.removeEventListener('keydown', this.disableArrowScroll)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    initializeFloor3D() {
      if (!this.space) {
        setTimeout(() => {
          this.initializeFloor3D()
        }, 100)
        return
      }
      this.buildingRef?.initializeFloor3D(this.space)
    },
    async loadSpaceData() {
      await this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.LOAD_SPACE_DATA),
        {
          spaceId: this.spaceId,
          commitName: projectConstants.mutation.SET_SPACE_DATA
        }
      )
    },
    sortSections() {
      this.sections = [...this.sectionsData].sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1
        }
        if (a.order_no > b.order_no) {
          return 1
        }
        return 0
      })
    },
    disableArrowScroll(e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
        e.preventDefault()
      }
    },
    handleScroll(event, element) {
      const scrollY = window.scrollY + 100
      document.querySelectorAll('a.navigation-link[href*="#"]').forEach((link) => {
        link.classList.remove('active')
        const section = document.getElementById(`${link.href.split('#')[1]}`).parentElement
        if (section.offsetTop <= scrollY && section.offsetTop + section.offsetHeight > scrollY) {
          link.classList.add('active')
        }
      })
    },
    smoothScroll(e) {
      e.preventDefault()
      document.querySelector(`#${e.target.getAttribute('href').split('#')[1]}`).scrollIntoView({
        behavior: 'smooth'
      })

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]')
      links.forEach((link) => {
        const activeLink = e.path[0]
        link.classList.remove('active')
        if (link === activeLink && !activeLink.classList.contains('active')) {
          link.classList.add('active')
        }
      })
    },
    removePageLoader() {
      if (this.$store.state.base.documentIsReady) {
        this.$store.dispatch(
          baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
          false
        )
      }
    }
  }
}
